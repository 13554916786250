<template>
  <v-container>
    <v-card>
      <v-card-text>
        <v-row>
          <!-- start date filters-->
          <v-col cols="12">
            <v-row no-gutters>
              <v-col class="mt-0 pt-0 pr-1" cols="12" sm="6">
                <BaseDateInput :rules="[v => !!v && this.validDate]" dense :label="$t('generic.lang_from')" outlined
                              type="date" v-model="start"/>
              </v-col>

              <v-col class="mt-0 pt-0 pl-1" cols="12" sm="6">
                <BaseDateInput :rules="[v => !!v && this.validDate]" dense :label="$t('generic.lang_till')" outlined
                              type="date" v-model="end"/>
              </v-col>
              <v-col cols="12">
                <v-btn @click="reload" class="mx-auto" :disabled="!this.start || !this.end || !this.validDate" depressed
                       block
                       color="primary">
                  {{this.$t('generic.lang_display')}}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>

          <v-col v-if="this.start && this.end && this.validDate" cols="12" class="pa-0">
            <Datatable ref="sales_per_day" :api-endpoint="ENDPOINTS.DATATABLES.ANALYTICS.SALESPERDATE"
                       :datatable-headers="datatableHeaders"
                       excel-file-name="sales-per-day"
                       :data="this.params"
                       :excel-columns="this.excelColumns"
            >
              <template v-slot:item.discount="{ item }">
                {{ item.discount | currency }}
              </template>
              <template v-slot:item.total="{ item }">
                {{ item.total | currency }}
              </template>
              <template v-slot:item.in_house_total="{ item }">
                {{ item.in_house_total | currency }}
              </template>
              <template v-slot:item.take_away_total="{ item }">
                {{ item.take_away_total | currency }}
              </template>
              <template v-slot:item.net_total="{ item }">
                {{ item.net_total | currency }}
              </template>
              <template v-slot:item.tax="{ item }">
                {{ item.tax | currency }}
              </template>
              <template v-slot:item.brut_total="{ item }">
                {{ item.brut_total | currency }}
              </template>
              <template v-slot:item.ec="{ item }">
                {{ item.ec | currency }}
              </template>
              <template v-slot:item.bar="{ item }">
                {{ item.bar | currency }}
              </template>
            </Datatable>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import moment from "moment";
import Datatable from "@/components/datatable/Datatable";
import BaseDateInput from "@/components/common/BaseDateInput.vue";
import {ENDPOINTS} from "@/config";

export default {
  name: "SalesPerDayComponent",
  components: {
    Datatable,
    BaseDateInput
  },
  data: () => ({
    start: "",
    end: "",
    ENDPOINTS
  }),
  computed: {
    excelColumns() {
      return [
        {
          label: this.$t('generic.lang_date'),
          field: 'date'
        },
        {
          label: this.$t('generic.lang_day'),
          field: 'day'
        },
        {
          label: this.$t('generic.lang_total'),
          field: 'total'
        },
        {
          label: this.$t('generic.lang_discount'),
          field: 'discount'
        },
        {
          label: this.$t('generic.lang_imHaus'),
          field: 'in_house_total'
        },
        {
          label: this.$t('generic.lang_offSiteSale'),
          field: 'take_away_total'
        },
        {
          label: this.$t('accounting.lang_totalNet'),
          field: 'net_total'
        },
        {
          label: this.$t('accounting.lang_taxes'),
          field: 'tax'
        },
        {
          label: this.$t('accounting.lang_totalBrut'),
          field: 'brut_total'
        },
        {
          label: this.$t('generic.lang_ec'),
          field: 'ec'
        },
        {
          label: this.$t('generic.lang_cash'),
          field: 'bar'
        }
      ]
    },
    datatableHeaders() {
      return [
        {
          text: this.$t('generic.lang_date'),
          value: 'date'
        },
        {
          text: this.$t('generic.lang_day'),
          value: 'day'
        },
        {
          text: this.$t('generic.lang_total'),
          value: 'total'
        },
        {
          text: this.$t('generic.lang_discount'),
          value: 'discount'
        },
        {
          text: this.$t('generic.lang_imHaus'),
          value: 'in_house_total'
        },
        {
          text: this.$t('generic.lang_offSiteSale'),
          value: 'take_away_total'
        },
        {
          text: this.$t('accounting.lang_totalNet'),
          value: 'net_total'
        },
        {
          text: this.$t('accounting.lang_taxes'),
          value: 'tax'
        },
        {
          text: this.$t('accounting.lang_totalBrut'),
          value: 'brut_total'
        },
        {
          text: this.$t('generic.lang_ec') ,
          value: 'ec'
        },
        {
          text: this.$t('generic.lang_cash') ,
          value: 'bar'
        }
      ]
    },
    validDate() {

      if (this.start && this.end) {
        return this.getFrom() < this.getTo();
      } else return true;

    },
    params() {
      return {
        start_date: this.getFrom(),
        end_date: this.getTo()
      }
    }
  },
  methods: {
    reload() {
      if (this.$refs.sales_per_day) {
        this.$refs.sales_per_day.getDataFromApi();
      }
    },
    getFrom() {
      let startDate = new Date(this.start);
      return moment.unix(startDate.getTime() / 1000).startOf("days").unix();
    },
    getTo() {
      let endDate = new Date(this.end);
      return moment.unix(endDate.getTime() / 1000).endOf("days").unix();
    }
  }
}
</script>

<style scoped>

</style>
